<template>
  <main class="book-open">
    <div class="book-open__content">
      <div class="book-open__image">
        <ImgComponent :head_img="data.img" class="book-open__image" />
        <ImgComponent :head_img="data.img" class="book-open__image" />
      </div>
      <div class="book-open__specifications">
        <span class="book-open__name">{{ data.title }}</span>
        <span class="book-open__author">{{ data.author ? data.author.title : "" }}</span>
        <div class="book-open-attributes">
          <div class="book-open__wrapper">
            <span class="book-open-attributes__title">ID товара</span>
            <span class="book-open-attributes__value">{{ data.id }}</span>
          </div>
          <div v-if="data.publication" class="book-open__wrapper">
            <span class="book-open-attributes__title">Издательство</span>
            <span class="book-open-attributes__value">{{
              data.publication ? data.publication.title : ""
            }}</span>
          </div>
          <div v-if="data.series" class="book-open__wrapper">
            <span class="book-open-attributes__title">Серия</span>
            <span class="book-open-attributes__value">{{ data.series ? data.series.title : "" }}</span>
          </div>
          <div v-if="data.writing_year" class="book-open__wrapper">
            <span class="book-open-attributes__title">Год издания</span>
            <span class="book-open-attributes__value">{{ data.writing_year }}</span>
          </div>
          <div v-if="data.size" class="book-open__wrapper">
            <span class="book-open-attributes__title">Количество страниц</span>
            <span class="book-open-attributes__value">{{ data.size }}</span>
          </div>
          <!--          <div class="book-open__wrapper">-->
          <!--            <span class="book-open-attributes__title">Размер</span>-->
          <!--            <span class="book-open-attributes__value">24 х 16 х 3</span>-->
          <!--          </div>-->
          <!--          <div class="book-open__wrapper">-->
          <!--            <span class="book-open-attributes__title">Тип обложки</span>-->
          <!--            <span class="book-open-attributes__value">Твердый переплет</span>-->
          <!--          </div>-->
          <!--          <div class="book-open__wrapper">-->
          <!--            <span class="book-open-attributes__title">Вес, г</span>-->
          <!--            <span class="book-open-attributes__value">700</span>-->
          <!--          </div>-->
          <div v-if="data.age_restriction" class="book-open__wrapper">
            <span class="book-open-attributes__title">Возрастное ограничение</span>
            <span class="book-open-attributes__value">{{ data.age_restriction }}</span>
          </div>
        </div>
        <div class="book-open__buttons">
          <a
            v-if="user_books.includes(data.id)"
            :href="`mylibstore://mylib.store/book/${data.id}`"
            class="btn btn--lg btn--main btn--reed"
            @click.prevent="buy(data.id)"
          >
            <IconComponent name="book" category="outline" />
            <span>Читать в приложении</span>
          </a>
          <button
            v-else
            @click="buy(data.id)"
            class="btn btn--lg"
            :class="{
              'btn--green': cart.includes(data.id),
              'btn--main': !cart.includes(data.id),
            }"
          >
            <IconComponent name="loading" category="default" v-if="loading === data.id" />
            <span v-if="cart.includes(data.id)">В корзине</span>
            <span v-else>В корзину {{ data.price }}р</span>
          </button>
          <button @click="openGiftModal(data.id)" class="btn btn--lg btn--yellow">
            <IconComponent category="default" name="gift" />
            <span>Подарить!</span>
          </button>
          <span class="btn btn--white btn--lg book-open__repost">
            <IconComponent name="export" category="default" />
            <ShareNetworksComponent column class="book-open__share" />
          </span>
        </div>
      </div>
    </div>
    <div v-if="data.categories" class="book-open-tabs">
      <div v-for="(item, i) in data.categories" :key="i" class="book-open-tabs__item">{{ item.title }}</div>
    </div>
    <span v-if="data.annotation" class="book-open__description">
      {{ data.annotation }}
    </span>
    <div class="book-open-mobile__buttons">
      <a
        v-if="user_books.includes(data.id)"
        :href="`mylibstore://mylib.store/book/${data.id}`"
        class="btn btn--lg btn--main btn--reed"
        @click.prevent="buy(data.id)"
      >
        <IconComponent name="book" category="outline" />
        <span>Читать в приложении</span>
      </a>
      <button
        v-else
        @click="buy(data.id)"
        class="btn btn--lg"
        :class="{
          'btn--green': cart.includes(data.id),
          'btn--main': !cart.includes(data.id),
        }"
      >
        <IconComponent name="loading" category="default" v-if="loading === data.id" />
        <span v-if="cart.includes(data.id)">В корзине</span>
        <span v-else>В корзину {{ data.price }}р</span>
      </button>
      <button @click="openGiftModal(data.id)" class="btn btn--lg btn--yellow book-open__gift">
        <IconComponent category="default" name="gift" />
      </button>
      <span class="btn btn--white btn--lg book-open__repost">
        <IconComponent name="export" category="default" />
        <ShareNetworksComponent column class="book-open__share" />
      </span>
    </div>
  </main>
</template>

<script>
import GET from "/src/graphql/books_item.graphql";
import ImgComponent from "components/ImgComponent.vue";
import IconComponent from "components/IconComponent.vue";
import LoginModal from "components/modals/components/LoginModal.vue";
import ShareNetworksComponent from "components/ShareNetworksComponent.vue";
import GiftModal from "components/modals/components/GiftModal.vue";

export default {
  name: "BookItem",
  components: { ShareNetworksComponent, IconComponent, ImgComponent },
  async asyncData({ store, apollo, route }) {
    await apollo.defaultClient
      .query({
        query: GET,
        fetchPolicy: "network-only",
        variables: {
          id: parseInt(route.params.id),
        },
      })
      .then(({ data }) => {
        store.state.book_item = data.books_item;
      });
    let queries = [];
    if (store.state.apollo_token && store.state.user) {
      queries.push(store.dispatch("cart", { apollo: apollo.defaultClient }));
      queries.push(store.dispatch("user_books", { apollo: apollo.defaultClient }));
    }
    await Promise.all(queries);
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    buy(id) {
      if (!this.user) {
        this.$store.state._modals.push({
          component: LoginModal,
        });
        return;
      }
      if (!this.loading) {
        this.loading = id;
        this.$store
          .dispatch("toggle_cart", { apollo: this.$apollo, id })
          .then(() => {
            this.$store.dispatch("cart", { apollo: this.$apollo });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    openGiftModal(id) {
      if (!this.user) {
        this.$store.state._modals.push({
          component: LoginModal,
        });
      } else {
        this.$store.state._modals.push({
          component: GiftModal,
          options: {
            id: id,
          },
        });
      }
    },
  },
  computed: {
    data() {
      return this.$store.state.book_item;
    },
    cart() {
      return this.$store.state.cart.map((c) => c.id);
    },
    user() {
      return this.$store.state.user;
    },
    user_books() {
      return this.$store.state.user_books;
    },
  },
  metaInfo() {
    let title = this.$store.state.book_item?.meta_title;
    let description = this.$store.state.book_item?.meta_description;
    let keywords = this.$store.state.book_item?.meta_keywords;
    return {
      title,
      meta: [
        {
          vmid: "keywords",
          name: "keywords",
          content: keywords,
        },
        {
          vmid: "description",
          name: "description",
          content: description,
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: title,
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: description,
        },
      ],
    };
  },
};
</script>

<style lang="stylus">
.book-open {
  width 100%
  max-width 1460px
  margin: 0 auto
  padding: 60px 30px
  display flex
  flex-direction column
  gap 30px

  +below(980px) {
    padding: 23px 16px
    padding-bottom 120px
  }

  &__content {
    display flex
    gap 100px

    +below(980px) {
      flex-direction column
      gap 20px
    }
  }

  &__image {
    border-radius 10px
    width 100%
    max-width 375px
    max-height 535px
    position relative
    overflow hidden

    img:first-child {
      max-width 375px
      object-fit cover
      object-position center
      width 100%
      height 100%
      absolute left top
      filter blur(10px)
    }

    img:last-child {
      max-width 375px
      object-fit contain
      object-position center
      width 100%
      height 100%
      position relative
      z-index 1
    }
  }

  &__specifications {
    display: flex;
    flex-direction column
    gap 30px
    width 100%

    +below(980px) {
      gap 20px
    }
  }

  &__name {
    font-weight: 700;
    font-size: 2.25rem
    line-height: 40px;

    +below(980px) {
      font-size: 1.25rem
      line-height: 25px
    }
  }

  &__author {
    font-weight: 300;
    font-size: 1.5rem
    line-height: 40px;

    +below(980px) {
      font-size: 0.875rem
      line-height: 17px
    }
  }

  &__wrapper {
    display flex
    justify-content space-between
    gap 15px
  }

  &__buttons {
    display flex
    gap 20px
    flex-wrap wrap

    +below(600px) {
      display none
    }
  }

  &__repost {
    padding: 0 23px
    position relative
    box-shadow: 0 5px 10px rgba(153, 108, 61, 0.2);

    +below(980px) {
      padding: 0 18px
    }

    &:hover {
      .book-open__share {
        opacity 1
        visibility visible
      }
    }
  }

  &__gift {
    padding: 0 23px

    +below(980px) {
      padding: 0 18px
    }
  }

  &__description {
    font-weight: 400;
    font-size: 1.25rem
    line-height: 40px;
    color var(--dark)

    +below(980px) {
      font-size: 1rem
    }
  }

  &__share {
    opacity 0
    visibility hidden
    transition var(--transition)
    position absolute
    top calc(100% + 5px)
    left 0
    padding 15px 10px 10px
    border-radius 16px
    box-shadow: 0 4px 16px 1px rgba(0,26,52,.16);
    z-index 5
    background var(--white)
    width auto
    +above(441px) {
      left: 50%;
      transform: translateX(-50%);
    }

    +below(980px) {
      translate(-90%, -140%)
    }

    +below(600px) {
      top: -15%;
      transform: translate(-80%, -100%);
    }

    &::after {
      content ""
      display block
      background var(--white)
      width 10px
      height: 10px
      position absolute
      z-index 4
      top: 0
      left 50%
      transform translate(-50%, -50%) rotate(45deg)

      +below(980px) {
        top auto
        bottom -10px
        left 90%
      }
    }
  }

  & .btn--reed {
    .icon svg path {
      fill var(--white)
    }
  }
}

.book-open-attributes {
  display flex
  flex-direction column
  gap 15px
  max-width 410px

  &__title {
    font-weight: 400;
    font-size: 1rem
    line-height: 19px;
    color: var(--gray);
    width 100%

    +below(980px) {
      font-size: 0.875rem
      line-height: 17px
    }
  }

  &__value {
    font-weight: 500;
    font-size: 1rem
    line-height: 19px;
    color: var(--dark);
    width 100%

    +below(980px) {
      font-size: 0.875rem
      line-height: 17px
    }
  }
}

.book-open-tabs {
  display flex
  gap 30px
  flex-wrap wrap

  +below(980px) {
    gap 20px
  }

  &__item {
    background #FFF7E4
    color var(--main)
    padding: 16px 20px
    font-weight: 500;
    font-size: 1rem
  }
}

.book-open-mobile__buttons {
  display none

  & .btn {
    font-size: 1.25rem
  }

  +below(600px) {
    display flex
    position: fixed;
    bottom 40px
    gap 10px
    padding-right: 16px
    z-index 1
  }
}
</style>
