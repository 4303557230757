<template>
  <div class="m gift-modal">
    <div class="m__header">
      <h1 class="m__title">Подарок</h1>
      <button class="m__close" type="button" @click="$emit('close')">
        <IconComponent category="linear" name="add" />
      </button>
    </div>
    <div class="m__body">
      <div class="gift-modal-tabs">
        <div
          v-for="(item, i) in tabs"
          :key="i"
          @click="changeActive(i)"
          class="gift-modal-tabs__item"
          :class="{ 'gift-modal-tabs__item--active': activeTab === i }"
        >
          {{ item.title }}
        </div>
      </div>
      <template v-if="activeTab === 0">
        <span class="gift-modal__description"
          >Купите электронную книгу и получите промокод для подарка! После оплаты книги в модальном окне вы
          получите <b>промокод</b>, который можно передать друзьям или близким в подарок.
        </span>
      </template>
      <template>
        <div v-show="activeTab === 1" class="m__col">
          <span>Введите промокод для получения подарка</span>
          <InputComponent v-model="form.code.value" :errors="form.code.errors" title="Промокод" />
        </div>
      </template>
      <div class="gift-modal__buttons">
        <button @click="$emit('close')" class="btn btn--gray">Завершить</button>
        <button @click="submit" class="btn btn--main">
          <span>Продолжить</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import BUY from "gql/OrderCreate.graphql";
import GET_BOOK from "gql/GetBookByGiftCode.graphql";
export default {
  name: "GiftModal",
  components: { InputComponent, IconComponent },
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      tabs: [{ title: "Подарить" }, { title: "Получить" }],
      activeTab: 0,
      form: {
        code: {
          value: "",
          errors: [],
        },
      },
    };
  },
  methods: {
    changeActive(i) {
      this.activeTab = i;
    },
    resetErrors() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].errors = [];
      });
    },
    parseGqlErrors(graphQLErrors) {
      console.log(graphQLErrors);
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].errors.push(err.extensions.validation[key][0]);
            }
          });
        }
      });
    },
    submit() {
      if (this.activeTab === 0) {
        this.$apollo
          .mutate({
            mutation: BUY,
            variables: {
              book_id: this.id,
              as_gift: true,
              cart: false,
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.apollo_token,
              },
            },
          })
          .then(({ data }) => {
            if (data && data.OrderCreate && data.OrderCreate.payment_url) {
              let link = document.createElement("a");
              link.href = data.OrderCreate.payment_url;
              link.click();
            }
          });
      } else {
        this.resetErrors();
        this.$apollo
          .mutate({
            mutation: GET_BOOK,
            variables: {
              code: this.form.code.value,
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.apollo_token,
              },
            },
          })
          .then(() => {
            this.$store.state._modals = [];
          })
          .catch(({ graphQLErrors }) => {
            this.parseGqlErrors(graphQLErrors);
          });
      }
    },
  },
};
</script>

<style lang="stylus">
.gift-modal {

  max-width 594px

  &__description {
    font-weight: 400;
    font-size: 1.25rem
    line-height: 40px;
    text-align: center;
    color: var(--dark);
  }

  &__buttons {
    display grid
    grid-template-columns 1fr 1fr
    grid-gap 19px
    width 100%
  }

  &-tabs {
    width 100%
    background var(--white)
    border-radius 8px
    display flex
    gap 5px

    &__item {
      width 100%
      padding 16px 10px
      text-align center
      color var(--dark)
      cursor pointer
      border-radius 8px
      transition var(--transition)

      &:hover {
        background: var(--yellow);
        color var(--main)
      }

      &--active {
        background: var(--yellow);
        color var(--main)
      }
    }
  }
}
</style>
